






































































import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/auth_service'
import { Roles } from '@/models/user/roles'
import { GenericError } from '@/services/error_service'

@Component
export default class ResetPassword extends Vue {
  protected authService: AuthService = new AuthService();
  protected token = '';
  protected userId = '';
  protected password = '';
  protected password2 = '';
  protected error = '';
  protected Roles = Roles;
  protected show1 = false;
  protected show2 = false;

  data () {
    return {
      password: '',
      password2: '',
      passwordRules: [
        (value: string) => !!value || 'Please enter a password.',
        (value: string) => (value && /\d/.test(value)) || 'At least one digit',
        (value: string) =>
          (value && /[^A-Za-z0-9]/.test(value)) ||
          'At least one special character',
        (value: string) =>
          (value && value.length > 7) || 'minimum 8 characters'
      ],
      password2Rules: [
        (value: string) => value === this.password || 'Passwords must match'
      ]
    }
  }

  created () {
    this.userId = this.$route.query.id as string
    this.token = this.$route.query.token as string
  }

  public async savePassword (): Promise<void> {
    try {
      const user = await this.authService.resetPassword(
        this.password,
        this.token,
        this.userId
      )
      const loader = this.$loading.show(
        { opacity: 1 },
        {
          before: this.$createElement(
            'h1',
            'Saving password and logging you in...'
          )
        }
      )
      setTimeout(() => {
        this.$router.replace('/logged-in')
        loader.hide()
      }, 2000)
      this.error = ''
    } catch (err) {
      if (err instanceof GenericError) {
        GenericError.popup(err.message)
      } else {
        this.error = err.message
      }
    }
  }
}
